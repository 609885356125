import calc from './../../utils/calc'
import { addDays, format } from "date-fns";

export default (salePaymentTerms) => {
  return makeSaleBills(salePaymentTerms)
}

function makeSaleBills(salePaymentTerms) {
  let saleBills = []

  for (let salePaymentTerm of salePaymentTerms) {

    if (salePaymentTerm.payment_term) {

      if (salePaymentTerm.payment_term.payment_method == 'cash' ||
        salePaymentTerm.payment_term.payment_method == 'check' ||
        salePaymentTerm.payment_term.payment_method == 'debit_card') {
        saleBills.push(getCashParams(salePaymentTerm))
      }

      if (salePaymentTerm.payment_term.payment_method == 'credit_card') {
        saleBills.push(getCreditCardParams(salePaymentTerm))
      }

      if (salePaymentTerm.payment_term.payment_method == 'bill') {
        saleBills = [...saleBills, ...getBillsParams(salePaymentTerm)];
      }
    }
  }

  return saleBills
}

function getCashParams(salePaymentTerm) {
  return {
    payment_method: salePaymentTerm.payment_term.payment_method,
    value: salePaymentTerm.value,
    due_days: null,
    due_date: null,
    installments: null,
  }
}

function getCreditCardParams(salePaymentTerm) {
  return {
    payment_method: salePaymentTerm.payment_term.payment_method,
    value: salePaymentTerm.value,
    due_days: null,
    due_date: null,
    installments: salePaymentTerm.installments,
  }
}

function getBillsParams(salePaymentTerm) {
  let billParams = []

  let interval = parseInstallmentsInterval(salePaymentTerm.payment_term.installments_interval)

  let parcelsValue = calc.divideParcelsValue(salePaymentTerm.value, interval.length)

  for (let intervalParcel of interval) {
    billParams.push(getBillParam(salePaymentTerm, intervalParcel, parcelsValue.value))
  }

  billParams[0].value = parcelsValue.firstValue

  return billParams
}

function getBillParam(salePaymentTerm, intervalParcel, value) {
  return {
    payment_method: salePaymentTerm.payment_term.payment_method,
    value: value,
    due_days: intervalParcel.due_days,
    due_date: intervalParcel.due_date,
    installments: null,
  }
}

function parseInstallmentsInterval(installmentsInterval) {
  return installmentsInterval.split("/").map((day) => ({
    due_days: day,
    due_date: format(addDays(new Date(), day), "yyyy-MM-dd"),
  }));
}
