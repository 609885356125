<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-text style="padding: 20px" class="text-center">
        <v-icon class="mt-4" color="primary" style="font-size: 70px"
          >mdi-check-decagram</v-icon
        >
        <v-card-title class="pt-1 pb-6 d-flex justify-center">
          <div>
            Venda Finalizada!
            <br />
            <h3>Nº {{ sale_number }}</h3>
          </div>
        </v-card-title>
      </v-card-text>
      <v-card-actions style="padding-bottom: 20px; padding-top: 0px">
        <v-row>
          <v-col>
            <v-btn
              @click="$router.push('/vendas')"
              text
              class="text-capitalize"
              block
            >
              Voltar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="newSale()"
              class="text-capitalize"
              color="primary"
              block
              >Nova venda</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      sale_number: null,
    };
  },

  methods: {
    open(sale_number) {
      this.dialog = true;
      this.sale_number = sale_number;
    },
    newSale() {
      this.$emit("newSale");
      this.dialog = false;
    },
  },
};
</script>
