<template>
  <div>
    <template v-for="(salePaymentTerm, index) in salePaymentTermsData">
      <v-row :key="index" v-if="boot">
        <v-col cols="6">
          <app-select
            height="20"
            v-model="salePaymentTerm.payment_term"
            label="Forma de Pgto"
            :items="paymentTermsByOperation"
            :item-text="(val) => val.name"
            :item-value="(val) => val"
            style="font-size: 12px;"
          />
        </v-col>
        <v-col cols="5">
          <app-number-field
            v-model="salePaymentTerm.value"
            label="Valor"
            height="20"
            style="font-size: 12px;"
          />
        </v-col>
        <v-col class="px-0" cols="1">
          <v-btn
            icon
            @click="handleRemoveSalePaymentTerm(salePaymentTerms, index)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <template v-if="salePaymentTerm.payment_term">
          <v-col
            cols="11"
            v-if="salePaymentTerm.payment_term.payment_method == 'credit_card'"
          >
            <InstallmentsSelect
              v-model="salePaymentTerm.installments"
              :max-installments="salePaymentTerm.payment_term.max_installments"
              style="font-size: 12px;"
            />
          </v-col>
        </template>
      </v-row>
      <!-- <v-divider v-if="salePaymentTerms.length > 1" class="my-4"/> -->
    </template>
    <v-row>
      <v-col class="text-right">
        <v-btn
          class="text-capitalize"
          text
          color="info"
          @click="handleAddPaymentTerms()"
        >
          Adicionar pagamento
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InstallmentsSelect from "../../payment-terms/ui/InstallmentsSelect.vue";
import makeSaleBillsService from "@/services/sale/makeSaleBillsService";

export default {
  components: { InstallmentsSelect },

  data: () => ({
    boot: false,

    paymentTerms: [],

    salePaymentTermsData: [
      {
        payment_term: null,
        value: 0,
      },
    ],
    saleBills: [],
  }),

  async mounted() {
    await this.loadPaymentTerms();
  },

  computed: {
    salePaymentTerms() {
      return this.$store.state.saleForm.saleForm.sale_payment_terms;
    },
    sale() {
      return this.$store.getters["saleForm/sale"];
    },

    saleNetTotal() {
      return this.sale.net_total
    },

    operation() {
      return this.$store.state.saleForm.saleForm.operation;
    },

    paymentTermsByOperation() {
      return this.paymentTerms.filter(
        (paymentTerm) => paymentTerm.operation_id == this.operation.id
      );
    },

    hasOnPaymentTerm() {
      return this.salePaymentTermsData.length == 1
    },

  },

  watch: {
    salePaymentTerms() {
      this.salePaymentTermsData = this.salePaymentTerms;
    },

    salePaymentTermsData: {
      deep: true,
      handler: function() {
        this.saleBills = makeSaleBillsService(this.salePaymentTermsData);

        this.$store.commit(
          "saleForm/setSalePaymentTerms",
          this.salePaymentTermsData
        );
        this.$store.commit("saleForm/setSaleBills", this.saleBills);
      },
    },

    saleNetTotal() {
      if (this.hasOnPaymentTerm) {
        this.$set(this.salePaymentTermsData[0], 'value', this.saleNetTotal)
      }
    }
  },

  methods: {
    open() {
      this.dialog = true;
    },

    async loadPaymentTerms() {
      await this.$http.index("settings/payment-term", { status : 1 }).then((response) => {
        this.paymentTerms = response.payment_terms;
        this.boot = true;
      });
    },

    handleAddPaymentTerms() {
      if (this.salePaymentTermsData.length == 0) {
        this.salePaymentTermsData.push({
          paymentTerm: null,
          value: this.sale.net_total,
        });
      } else {
        this.salePaymentTermsData.push({
          paymentTerm: null,
          value: 0,
        });
      }
    },

    handleRemoveSalePaymentTerm(salePaymentTerms, index) {
      this.$delete(salePaymentTerms, index);
    },
  },
};
</script>

<style></style>
