<template>
  <v-card v-bind="$attrs" color="grey lighten-3 " elevation="0">
    <v-card-text>
      <v-row>
        <v-col>Total dos produtos</v-col>
        <v-col class="text-right">
          <h2>{{ $format.decimal(sale.product_total) }}</h2>
        </v-col>
      </v-row>
      <template v-if="sale.product_discount > 0">
        <v-row>
          <v-col>Desconto nos produtos</v-col>
          <v-col class="text-right">
            <h3>{{ $format.decimal(sale.product_discount) }}</h3>
          </v-col>
        </v-row>
        <v-row v-if="sale.product_discount > 0">
          <v-col>Sub Total</v-col>
          <v-col class="text-right">
            <h3>{{ $format.decimal(sale.sub_total) }}</h3>
          </v-col>
        </v-row>
      </template>

      <v-row class="mt-5">
        <v-col>
          <app-number-field
            v-model="generalDiscountPercent"
            label="Desconto (%)"
            @change="handleChangeDiscountPercent()"
          />
        </v-col>
        <v-col>
          <app-number-field
            v-model="generalDiscount"
            label="Desconto (R$)"
            @change="handleChangeDiscount()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>Desconto total da venda</v-col>
        <v-col class="text-right">
          <h3 class="text--error">
            {{ $format.decimal(sale.discount_percent) }} %
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>Total da Venda</v-col>
        <v-col class="text-right">
          <h2>{{ $format.decimal(sale.net_total) }}</h2>
        </v-col>
      </v-row>
      <div
        class="my-4"
        style="border-bottom: 1px dashed rgba(0, 0, 0, 0.12)"
      ></div>
      <v-row>
        <v-col>Pagamento</v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0">
          <PaymentForm ref="PaymentForm" />
        </v-col>
      </v-row>
      <v-row>
        <template v-if="!showNotesSection">
          <v-col class="text-right">
            <v-btn
              class="text-capitalize"
              color="info"
              small
              text
              @click="handleAddNotes()"
            >
              Adicionar observação
            </v-btn>
          </v-col>
        </template>
        <template v-if="showNotesSection">
          <v-col>
            <v-textarea
              v-model="sale.notes"
              label="Observações"
              outlined
              rows="3"
            />
          </v-col>
        </template>
      </v-row>
      <v-row>
        <v-col v-if="hasNegotiation" cols="12" class="d-flex flex-column">
          <div>Valor: {{ $format.decimal(sale.negotiation_value) }}</div>
          <div>Observação: {{ sale.negotiation_comments }}</div>
        </v-col>
        <v-col class="text-right">
          <v-btn color="info" small text @click="handleAddNegotiation()">
            {{ hasNegotiation ? "Editar" : "Adicionar" }} Proposta
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasErrors">
        <v-col>
          <v-alert type="error">
            <template>
              <span v-for="(error, index) in errorMessages" :key="index">
                {{ error.message }}
              </span>
              <br />
            </template>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="text-capitalize"
            color="secondary"
            block
            @click="$emit('store', { status: 'draft' })"
          >
            Salvar Rascunho
          </v-btn>
        </v-col>
        <v-col v-if="canStoreAsProposal">
          <v-btn
            class="text-capitalize"
            color="secondary"
            block
            @click="$emit('store', { status: 'proposal' })"
          >
            Salvar Orçamento
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="confirmProposal">
        <v-col cols="12">
          <v-btn
            class="text-capitalize"
            color="primary"
            block
            @click="$emit('store', { status: 'order' })"
          >
            Confirmar Pedido
          </v-btn>
        </v-col>
        
        <v-col v-if="operation && operation.id != 2" class="text-right">
          <v-btn
            class="text-capitalize"
            text
            color="primary"
            @click="$emit('store', { status: 'waiting_approval' })"
          >
            Pedir aprovação
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <SaleNegotiationForm ref="SaleNegotiationForm" />
  </v-card>
</template>

<script>
import PaymentForm from "./PaymentForm";
import SaleNegotiationForm from "../forms/SaleNegotiationForm";

export default {
  components: { PaymentForm, SaleNegotiationForm },

  data: () => ({
    showNotesSection: false,
    showNegotiationSection: false,
    generalDiscount: 0,
    generalDiscountPercent: 0,
  }),

  computed: {
    confirmProposal() {
      if (this.$store.state.saleForm.saleForm.status == "proposal") {
        return this.$store.state.saleForm.saleForm.is_valid_proposal;
      }
      return true;
    },
    canStoreAsProposal() {
      if (this.sale.operation) {
        return this.sale.operation.id == 1;
      }
    },

    operation() {
      return this.$store.state.saleForm.saleForm.operation;
    },

    sale() {
      return this.$store.getters["saleForm/sale"];
    },

    subTotal() {
      return this.sale.sub_total;
    },

    hasErrors() {
      return this.$store.getters["saleForm/hasErrors"];
    },

    errorMessages() {
      return this.$store.getters["saleForm/errorMessages"];
    },

    generalDiscountState() {
      return this.$store.state.saleForm.saleForm.general_discount;
    },

    notes: {
      get() {
        return this.$store.state.saleForm.saleForm.notes;
      },
      set(value) {
        this.$store.commit("saleForm/setNotes", value);
      },
    },
    hasNegotiation() {
      return this.sale.has_negotiation;
    },
  },

  watch: {
    generalDiscountState() {
      this.generalDiscount = this.$store.state.saleForm.saleForm.general_discount;
      this.generalDiscountPercent = this.calcDiscountPercent(this.subTotal, this.generalDiscount); 
    },

    subTotal() {
      this.handleChangedSubTotal()
    },

    notes() {
      if (this.notes) {
        this.showNotesSection = true;
      }
    },
  },

  methods: {

    handleChangeDiscount() {
      this.generalDiscountPercent = this.calcDiscountPercent(this.sale.sub_total, this.generalDiscount);

      this.$store.commit("saleForm/setGeneralDiscount", this.generalDiscount);
    },

    handleChangeDiscountPercent() {

      let percent = this.calcDiscountPercent(this.sale.sub_total, this.generalDiscount);
      
      if (this.$calc.round(percent, 2) != this.$calc.round(this.generalDiscountPercent, 2)) {
        
        this.generalDiscount = this.calcDiscountValue(this.sale.sub_total, this.generalDiscountPercent);
  
        this.$store.commit("saleForm/setGeneralDiscount", this.generalDiscount);
      }

    },

    handleChangedSubTotal() {
      this.generalDiscount = this.calcDiscountValue(this.sale.sub_total, this.generalDiscountPercent);

      this.$store.commit("saleForm/setGeneralDiscount", this.generalDiscount);
    },

    calcDiscountValue(baseCalc, percent) {
      return baseCalc * (percent / 100);
    },

    calcDiscountPercent(baseCalc, value) {
      return (value * 100) / baseCalc;
    },

    handleAddNotes() {
      this.showNotesSection = true;
    },
    handleAddNegotiation() {
      this.$refs.SaleNegotiationForm.open();
    },
  },
};
</script>

<style></style>
