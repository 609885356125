<template>
  <div>
    <div class="d-flex mb-5">
      <v-btn
        :block="$vuetify.breakpoint.mobile"
        outlined
        color="info"
        @click="handleAddProduct()"
      >
        <v-icon left> mdi-plus </v-icon>
        Adicionar produto
      </v-btn>
    </div>
    <template v-if="hasProducts">
      <v-data-table
        v-if="!$vuetify.breakpoint.mobile"
        dense
        hide-default-footer
        disable-sort
        :headers="headers"
        :items="saleProducts"
        :items-per-page="-1"
        @click:row="($event, $item) => handleEditOrderProduct($item.index)"
        :item-class="validateProduct"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div>
            <template v-if="item.product">
              {{ item.product.code }} - {{ item.product.name }}
            </template>
          </div>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          {{ $format.decimal(item.quantity) }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ $format.decimal(item.price) }}
        </template>
        <template v-slot:[`item.product_discount`]="{ item }">
          {{ $format.decimal(item.product_discount) }}
        </template>
        <template v-slot:[`item.general_discount`]="{ item }">
          {{ $format.decimal(item.general_discount) }}
        </template>
        <template v-slot:[`item.net_total`]="{ item }">
          {{ $format.decimal(item.net_total) }}
        </template>
        <template v-slot:[`item.net_price`]="{ item }">
          {{ $format.decimal(item.net_price) }}
        </template>
        <template v-slot:[`item.company`]="{ item }">
          {{ item.product_company.company.short_name }}
        </template>

        <template v-slot:[`item.action`]="{ index }">
          <v-btn
            @click.stop="handleRemoveItem(index)"
            text
            small
            icon
            color="primary"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-data-table
        v-if="$vuetify.breakpoint.mobile"
        dense
        hide-default-footer
        hide-default-header
        disable-sort
        :headers="mobileHeaders"
        :items="saleProducts"
        :items-per-page="-1"
        @click:row="($event, $item) => handleEditOrderProduct($item.index)"
        :mobile-breakpoint="0"
        :item-class="validateProduct"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="my-2">
            <template v-if="item.product">
              <v-chip small>
                {{ item.product_company.company.short_name }}
              </v-chip>
              {{ item.product.code }} - {{ item.product.name }}
            </template>
          </div>

          <div class="d-flex justify-space-between my-2">
            <b> Qtd: {{ $format.decimal(item.quantity) }} </b>

            <b> Total.: {{ $format.decimal(item.net_total) }} </b>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ index }">
          <v-btn
            @click.stop="handleRemoveItem(index)"
            text
            small
            icon
            color="primary"
          >
            <v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    mobileHeaders: [
      { text: "Produto", value: "name", align: "start" },
      { text: "", value: "action", align: "center", width: "1%" },
    ],
    headers: [
      { text: "Produto", value: "name", align: "start", width: "30%" },
      { text: "Quantidade", value: "quantity", align: "right" },
      { text: "Preço", value: "price", align: "right" },
      { text: "Desc Produto", value: "product_discount", align: "right" },
      { text: "Desc Geral", value: "general_discount", align: "right" },
      { text: "Preço Final", value: "net_price", align: "right" },
      { text: "Total", value: "net_total", align: "right" },
      { text: "Empresa", value: "company", align: "right" },
      { text: "", value: "action", align: "center", width: "9%" },
    ],
  }),

  computed: {
    saleProducts() {
      return this.$store.getters["saleForm/sale"].sale_products;
    },

    hasProducts() {
      return this.$store.getters["saleForm/hasProducts"];
    },

    generalDiscountPercent() {
      return this.$store.getters["saleForm/generalDiscountPercent"];
    },
  },

  methods: {
    handleEditOrderProduct(index) {
      this.$emit("editSaleProduct", index);
    },

    handleRemoveItem(index) {
      this.$store.commit("saleForm/removeProduct", index);
    },

    handleAddProduct() {
      this.$emit("addProduct");
    },

    calcNetPrice(item) {
      let general_discount =
        (item.price - item.product_discount_unity) *
        (this.generalDiscountPercent / 100);

      return item.price - item.product_discount_unity - general_discount;
    },

    calcTotal(item) {
      return this.calcNetPrice(item) * item.quantity;
    },

    validateProduct(item) {
      return (
        this.checkIfHasPriceZero(item) ||
        this.checkIfSellMultiple(item) ||
        this.checkProductWithStockNegative(item)
      );
    },
    checkIfHasPriceZero(item) {
      return item.net_total ? null : "notPrice";
    },

    checkIfSellMultiple(item) {
      let division = item.quantity / item.product_variant.sell_multiple;

      let result = Math.abs(Math.round(division) - division) > 0.00001;

      return result ? "invalidSellMultiple" : null;
    },
    checkProductWithStockNegative(item) {
      if (!item.product.allow_sell_stock_negative) {
        return parseFloat(item.quantity) >
          parseFloat(item.product_company.available_stock)
          ? "invalidSellMultiple"
          : null;
      }
    },
  },
};
</script>

<style lang="sass">
.notPrice
  color: white !important
  background-color: red !important
  .v-icon.v-icon
    color: white !important
.invalidSellMultiple
  color: white !important
  background-color: orange !important
  .v-icon.v-icon
    color: white !important
</style>
