var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mb-5"},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.mobile,"outlined":"","color":"info"},on:{"click":function($event){return _vm.handleAddProduct()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Adicionar produto ")],1)],1),(_vm.hasProducts)?[(!_vm.$vuetify.breakpoint.mobile)?_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.saleProducts,"items-per-page":-1,"item-class":_vm.validateProduct},on:{"click:row":function ($event, $item) { return _vm.handleEditOrderProduct($item.index); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.product)?[_vm._v(" "+_vm._s(item.product.code)+" - "+_vm._s(item.product.name)+" ")]:_vm._e()],2)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.quantity))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.price))+" ")]}},{key:"item.product_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_discount))+" ")]}},{key:"item.general_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.general_discount))+" ")]}},{key:"item.net_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.net_total))+" ")]}},{key:"item.net_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.net_price))+" ")]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_company.company.short_name)+" ")]}},{key:"item.action",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"text":"","small":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveItem(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","hide-default-header":"","disable-sort":"","headers":_vm.mobileHeaders,"items":_vm.saleProducts,"items-per-page":-1,"mobile-breakpoint":0,"item-class":_vm.validateProduct},on:{"click:row":function ($event, $item) { return _vm.handleEditOrderProduct($item.index); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[(item.product)?[_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.product_company.company.short_name)+" ")]),_vm._v(" "+_vm._s(item.product.code)+" - "+_vm._s(item.product.name)+" ")]:_vm._e()],2),_c('div',{staticClass:"d-flex justify-space-between my-2"},[_c('b',[_vm._v(" Qtd: "+_vm._s(_vm.$format.decimal(item.quantity))+" ")]),_c('b',[_vm._v(" Total.: "+_vm._s(_vm.$format.decimal(item.net_total))+" ")])])]}},{key:"item.action",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"text":"","small":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveItem(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }