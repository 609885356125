<template>
  <app-select
    v-bind="$attrs"
    v-on="$listeners"
    label="Parcelas"
    :items="installments"
    :item-text="(val) => val.text"
    :item-value="(val) => val.installments"
  />
    <!-- height="20"
    style="font-size: 12px;" -->
</template>

<script>
export default {
  props : {
    maxInstallments : {}
  },

  
  computed : {
    installments() {
      let arr = Array.from({ length: this.maxInstallments }, (v, i) => i + 1)

      return arr.map((val) => ({ installments : val, text : val + 'x'}))
    },
  }
};
</script>

<style></style>
