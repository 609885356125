<template>
  <app-text-field
    v-model="text"
    prepend-inner-icon="mdi-barcode-scan"
    :loading="isLoading"
    v-debounce:400ms="search"
    :autofocus="true"
    label="GTIN"
  />
</template>

<script>
export default {
  components: {},
  props: {
    value: {},
  },

  data: () => ({
    url: "product/product-search-by-gtin",

    isLoading: false,

    text: null,
    product: {},
  }),
  
  watch: {
    value() {
      this.clear();
    },
  },
  
  computed: {
    hasProduct() {
      return this.product.id != null;
    },

    gtin: {
      set(value) {
        this.text = value;
      },
      get() {
        return this.text.replace(/\D/g, "");
      },
    },

    isValid() {
      return this.gtin != null && this.gtin != "";
    },
  },

  methods: {
    async search() {
      if (this.isValid) {
        this.isLoading = true;

        await this.$http
          .index(this.url, { gtin: this.gtin })
          .then((response) => {
            this.product = JSON.parse(JSON.stringify(response.product));

            this.setProductData();

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
          });
      }
    },

    setProductData() {
      if (this.hasProduct) {
        this.$emit("input", this.product);
        this.clear();
      }
      if (!this.hasProduct) {
        this.showAlert();
      }
      this.clear();
    },

    showAlert() {
      this.$snackbar({
        message: "Produto não encontrado",
        props: {
          color: "error",
          timeout: 1000,
        },
      });
    },

    clear() {
      this.gtin = null;
      this.product = null;
    },
  },
};
</script>

 
