<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span> Proposta </span>

        <v-btn @click="deleteNegotiation()" class="ml-auto" icon color="error">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <!-- <v-col>
            <app-text-field
              v-model="form.negotiation_expiration_date"
              label="Data de Expiração"
              type="date"
            />
          </v-col> -->
          <v-col>
            <app-number-field
              v-model="form.negotiation_value"
              label="Valor da Proposta"
              type="date"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.negotiation_comments"
              label="Observações da Proposta"
              outlined
              rows="3"
            />
            {{ form.negotiation_expiration_date }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text color="secondary"> Cancelar </v-btn>
        <v-btn @click="handleSave()" color="primary"> Adicionar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addDays, format } from "date-fns";

export default {
  data() {
    return {
      dialog: false,
      baseForm: {
        negotiation_expiration_date: null,
        negotiation_value: null,
        negotiation_comments: null,
        has_negotiation: 0,
      },
      form: {},
    };
  },

  computed: {
    sale() {
      return this.$store.state.saleForm.saleForm;
    },
  },

  methods: {
    open() {
      this.dialog = true;

      this.reset();

      if (this.sale.has_negotiation) {
        this.setNegotiation();
      }
    },

    reset() {
      this.form = {
        negotiation_value: null,
        negotiation_comments: null,
        has_negotiation: 1,
      };
    },

    setNegotiation() {
      this.form = JSON.parse(JSON.stringify(this.sale));
    },

    deleteNegotiation() {
      this.$store.commit("saleForm/setNegotiation", this.baseForm);
      this.dialog = false;
      this.$emit("store");
    },

    handleSave() {
      this.$emit("store");
      this.$store.commit("saleForm/setNegotiation", this.form);

      this.dialog = false;
    },
  },
};
</script>

<style>
</style>