<template>
  <div>
    <v-alert
      v-if="(showInProposalAlert || showUpdatePriceAlert) && id"
      border="bottom"
      dense
      type="error"
    >
      Existem produtos com valores desatualizados
      <template v-slot:append>
        <v-btn @click="handleUpdatePrice()" text> Atualizar </v-btn>
      </template>
    </v-alert>

    <div v-if="hasSalePreSaved" class="mb-4 text-right">
      <v-btn @click="setSaleDraft()" color="success">
        Recuperar {{ operation.description }}
      </v-btn>
    </div>

    <v-row>
      <v-col>
        <v-icon left>{{ operation.icon }} </v-icon>
        {{ operation.description }}
        <template v-if="id">#{{ number }}</template>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="8">
        <v-row>
          <v-col md="8">
            <CustomerSearch v-model="customer" clearable />
            <CustomerDetailsSection :customer="customer" />
            <v-alert
              v-if="customer && customer.alert_notes"
              border="left"
              type="error"
              dense
              outlined
            >
              {{ customer.alert_notes }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="canEditDeliveryType">
          <v-col>
            Tipo de Entrega:
            <v-chip-group column v-model="delivery_type">
              <v-chip filter :value="'no_delivery'" color="primary">
                Venda balcão
              </v-chip>
              <v-chip filter :value="'delivery'">Entrega Futura</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SaleProductsList
              @editSaleProduct="handleEditSaleProduct($event)"
              @addProduct="handleAddProduct()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4">
        <v-row class="d-none d-md-block">
          <v-col>
            <span v-if="salesman">
              <v-icon left>mdi-account</v-icon> {{ salesman.name }}
            </span>
            <br />
            <span v-if="company">
              <v-icon left>mdi-domain</v-icon> {{ company.name }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SaleTotals @store="handleStore($event)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <SaleProductForm ref="SaleProductForm" />
    <SaleSuccessDialog ref="SaleSuccessDialog" @newSale="newSale()" />
  </div>
</template>

<script>
import CustomerDetailsSection from "@/components/customer/sections/CustomerDetailsSection.vue";
import CompanySelect from "@/components/company/ui/CompanySelect";
import SaleTotals from "@/components/sales/sections/SaleTotals.vue";
import SaleProductForm from "@/components/sales/forms/SaleProductForm.vue";
import CustomerSearch from "@/components/customer/ui/CustomerSearch";
import SaleProductsList from "@/components/sales/sections/SaleProductsList";
import SaleSuccessDialog from "@/components/sales/sections/SaleSuccessDialog.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";

export default {
  components: {
    CompanySelect,
    CustomerSearch,
    SaleProductsList,
    SaleTotals,
    SaleProductForm,
    SaleSuccessDialog,
    CustomerDetailsSection,
    SalesmanSearch,
  },

  props: {
    id: {},
    operation: {},
    // movement_stock: {},
    // movement_stock_type: {},
    // movement_finance: {},
    // movement_finance_type: {},
  },

  data: () => ({
    saleProductEditIndex: null,
    saleDraft: {},
  }),

  async mounted() {
    await this.newSale();
    await this.loadDraftLocal();
  },

  computed: {
    number() {
      return this.$store.state.saleForm.saleForm.number;
    },
    showInProposalAlert() {
      return (
        !this.$store.state.saleForm.saleForm.is_valid_proposal &&
        this.status == "proposal"
      );
    },

    showUpdatePriceAlert() {
      var sale = this.$store.state.saleForm.saleForm;

      var products = [];
      sale.sale_products.forEach((row) => {
        if (row.product_variant.price_forward != row.price) {
          products.push(row);
        }
      });
      return products.length > 0;
    },

    canSalesOtherUser() {
      return !this.$acl.can("salesOtherUser");
    },

    company: {
      get() {
        return this.$store.state.saleForm.saleForm.company;
      },
      set(value) {
        this.$store.commit("saleForm/setCompany", value);
      },
    },

    customer: {
      get() {
        return this.$store.state.saleForm.saleForm.customer;
      },
      set(value) {
        this.$store.commit("saleForm/setCustomer", value);
      },
    },

    salesman: {
      get() {
        return this.$store.state.saleForm.saleForm.salesman;
      },
      set(value) {
        this.$store.commit("saleForm/setSalesman", value);
      },
    },

    delivery_type: {
      get() {
        return this.$store.state.saleForm.saleForm.delivery_type;
      },
      set(value) {
        this.$store.commit("saleForm/setDeliveryType", value);
      },
    },

    status: {
      get() {
        return this.$store.state.saleForm.saleForm.status;
      },
      set(value) {
        this.$store.commit("saleForm/setStatus", value);
      },
    },
    canEditDeliveryType() {
      return this.operation.movement_stock_type == "out";
    },

    key() {
      var salesman = `salesman-${this.salesman?.id}`;
      var company = `company-${this.company?.id}`;
      var operation = `operation-${this.operation?.id}`;

      return `${salesman}-${company}-${operation}`;
    },

    sale() {
      return this.$store.state.saleForm.saleForm;
    },

    hasSalePreSaved() {
      return (
        this.saleDraft &&
        this.saleDraft.id &&
        this.saleDraft.data.sale_products.length
      );
    },

    hasProducts() {
      return this.sale.sale_products.length;
    },
  },

  watch: {
    sale: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.storeSaleInLocalStorage();
      },
    },
  },

  methods: {
    newSale() {
      this.reset();
      this.setFormData();
    },

    reset() {
      this.$store.commit("saleForm/reset");
    },

    setFormData() {
      this.$store.commit("saleForm/setOperation", this.operation);

      let employee = JSON.parse(
        JSON.stringify(this.$store.state.auth.userData.employee)
      );

      this.salesman = employee;
      this.company = this.salesman.company;

      if (this.operation.movement_stock_type == "in") {
        this.delivery_type = "no_delivery";
      }
    },

    handleAddProduct() {
      this.$refs.SaleProductForm.addSaleProduct();
    },

    handleEditSaleProduct(index) {
      this.$refs.SaleProductForm.editSaleProduct(index);
    },

    async handleStore(status) {
      this.status = status.status;

      this.$loading.start();
      this.$store
        .dispatch("saleForm/storeSale")
        .then((response) => {
          this.$loading.finish();
          this.$refs.SaleSuccessDialog.open(response.number);
          this.clearSaleDraft();
        })
        .catch((error) => {
          this.$loading.finish();
          
        });
    },

    async handleUpdatePrice() {
      this.$loading.start();
      this.$http
        .update("sale/update-price", this.id)
        .then((response) => {
          this.$emit("update");
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    async loadDraftLocal() {
      this.saleDraft = await this.$db.sales.get({ key: this.key });
    },

    async storeSaleInLocalStorage() {
      if (this.hasProducts) {
        if (this.hasSalePreSaved) {
          await this.$db.sales
            .update(this.saleDraft.id, {
              key: this.key,
              data: this.sale,
            })
            .then((response) => {})
            .catch((error) => {});
        }
        if (!this.hasSalePreSaved) {
          this.$db.sales
            .add({ key: this.key, data: this.sale })
            .then(async () => await this.loadDraftLocal());
        }
      }
    },

    async clearSaleDraft() {
      if (this.hasSalePreSaved) {
        await this.$db.sales.delete(this.saleDraft.id);
        this.saleDraft = {};
      }
    },

    setSaleDraft() {
      this.$store.commit("saleForm/setSale", this.saleDraft.data);
    },
  },
};
</script>

<style></style>
